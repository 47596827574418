function ProposalController ($scope, $log, $location, proposalService) {
    $scope.staticContent = proposalService.getStaticContent();

    proposalService.getProposalContent().then(function (res) {
        if (res.$value === null) {
            $log.error('Proposal not found...');
            $location.path('/404');
        } else {
            $scope.proposalContent = res;
        }
    })

    $scope.state = {
        isUnlocked: false, // TODO: switch this back to false
        enteredPass: undefined
    };

    $scope.m = {
        getNumber: num => new Array(num),
        unlock: function () {
            // TODO: call proposalService.requestUnlock and handle promise w/ .then()
            if ($scope.state.enteredPass === $scope.proposalContent.client.contact) {
                $scope.state.isUnlocked = true;
            } else {
                alert('Incorrect Access Code. Please try again.');
            }
        }
    };


}

function HomeController ($scope) {
    $scope.state = {};

    $scope.getProposal = function () {
        console.log('getting proposal...');
        return false; //TODO map a service method
    }
}

export { ProposalController, HomeController }
