function ProposalConfig ($routeProvider) {
    $routeProvider
        .when('/', {
            data: {
                pageTitle: "Proposals - Done Digital"
            },
            templateUrl: '/proposalsApp/home.html',
            controller: 'HomeController'
        })
        .when('/404', {
            data: {
                pageTitle: "Error: Not Found - Proposals - Done Digital"
            },
            templateUrl: '/proposalsApp/404.html'
        })
        .when('/proposal/:proposalSlug', {
            data: {
                pageTitle: "Proposal - Done Digital"
            },
            templateUrl: '/proposalsApp/proposal.html',
            controller: 'ProposalController'
        })
        .otherwise({ redirectTo: '/404' });
}

export { ProposalConfig }
