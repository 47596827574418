import 'firebase'
import angular from 'angular'
import ngSanitize from 'angular-sanitize'
import ngPageTitle from 'ng-page-title'
import ngRoute from 'angular-route'
import firebase from 'angularfire'

import { ProposalController, HomeController } from './proposalsApp/controllers'
import { ProposalService } from './proposalsApp/services'
import { ProposalConfig } from './proposalsApp/config'

angular.module('proposalsApp', ['ngRoute', 'firebase', 'ngPageTitle']);

angular.module('proposalsApp')
    .config(['$routeProvider', ProposalConfig])
    .service('proposalService', ['$http', '$log', '$routeParams', '$firebaseObject', ProposalService])
    .controller('HomeController', ['$scope', HomeController])
    .controller('ProposalController', ['$scope', '$log', '$location', 'proposalService', ProposalController]);
