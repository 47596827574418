function ProposalService ($http, $log, $routeParams, $firebaseObject) {

    this.getStaticContent = function () {
        let ref = new Firebase("https://done-digital.firebaseio.com");
        return $firebaseObject(ref.child('static'));
    }

    this.getProposalContent = function () {
        let ref = new Firebase("https://done-digital.firebaseio.com");
        return $firebaseObject(ref.child('proposals').child($routeParams.proposalSlug)).$loaded();
    }

    //this.requestUnlock = function (password) {
    // TODO: return $http.post('<some_url>', password);
    // nb: need to work out how this micro-service will run...
    //     inside controller, should expose this method indirectly on $scope
    //      so a wrapper method is called from DOM, which includes the
    //      handling of the promise and $scope update
    //}
}

export { ProposalService }
